import {
    APA_ROLL_BACK_LIST_REQUEST,
    APA_ROLL_BACK_LIST_SUCCESS,
    APA_ROLL_BACK_LIST_FAIL,
} from "../../../Constant/ApaManagement/MakerSubmission/ApaRollBackListConstant";


export const getApaRollBackListDataReducer = (state = { apaRollBackListData: [], loading: false }, action) => {

    switch (action.type) {
        case APA_ROLL_BACK_LIST_REQUEST:
            return {
                ...state,
                loading: true,
            };
        case APA_ROLL_BACK_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                apaRollBackListData: action.payload

            }
        case APA_ROLL_BACK_LIST_FAIL:
            return {
                ...state,
                loading: false,
                error: action.error
            }

        default:
            return state
    }


};
